<template>
  <div class="ui-voucher">
    <div
      @click="openModal"
      class="ui-voucher__card"
      :class="{
        '-is-used': used
      }"
    >
      <h5 class="ui-voucher__title">{{ name }}</h5>

      <span
        v-if="hasChild"
        class="ui-voucher__transfered"
      >{{ $t('conn3ct-wallet.perk_transfered') }}</span>

      <span
        v-if="!used && from && to"
        class="ui-voucher__interval"
      >{{ $t('conn3ct-wallet.perk_restriction_message', { from: from.toLocaleString(), to: to.toLocaleString() }) }}</span>

      <template v-else>
        <span
          v-if="voucher.lastUsage"
          class="ui-voucher__interval"
        >{{ $t('conn3ct-wallet.perk_usage_message', { date: voucher.lastUsage.toLocaleString() }) }}</span>

        <span
          class="ui-voucher__code"
        >{{ voucher.code }}</span>
      </template>
    </div>

    <popins-modal
      @close="showModal = false"
      class="modal-voucher"
      position="middle-center"
      :size="$pepper.Size.XS"
      :visible="showModal"
    >
      <template v-slot:header>
        <h4>{{ name }}</h4>
      </template>

      <div class="modal-voucher__body">
        <div
          v-if="voucherType === 'wallet' || voucherType === 'perks'"
          class="modal-voucher__informations"
        >
          <template v-if="voucherType === 'perks'">
            <div
              v-for="perk in perks"
              class="modal-voucher__perk"
            >
              <h5 class="modal-voucher__perk-name">
                <ui-icon class="modal-voucher__check" glyph="check" />
                {{ perk.name }}
              </h5>
              <div
                v-html="perk.clientMessage"
                class="modal-voucher__perk-descr"
              ></div>

              <div
                v-if="hasWalletMessage(perk)"
                class="modal-voucher__perk-wallet-message-wrapper">
                <h6 class="modal-voucher__perk-wallet-message-title">{{ $t('conn3ct-wallet.perk_wallet_message_title') }}</h6>
                <div
                  v-html="translate(perk.walletMessage)"
                  class="modal-voucher__perk-wallet-message"
                ></div>
              </div>
            </div>
          </template>

          <div
            v-if="voucherType === 'wallet'"
            class="modal-voucher__perk"
          >
            <h5 class="modal-voucher__perk-name">
              <ui-icon class="modal-voucher__check" glyph="check" />
              {{ $t('conn3ct-wallet.voucher_wallet_title') }}
            </h5>

            <p class="modal-voucher__perk-descr">{{ $t('conn3ct-wallet.voucher_wallet_message', { amount, unit }) }}</p>
          </div>
        </div>

        <div class="modal-voucher__code-wrapper">
          <div class="modal-voucher__qr">
            <img :src="qr" />
          </div>

          <actions-button
            @click="onCopy"
            :appearance="$pepper.Appearance.SUBTLE"
            class="modal-voucher__copy"
          >
            <div class="modal-voucher__code-block">
              <span class="modal-voucher__code">{{ code }}</span>

              <div class="modal-voucher__copy-icon">
                <icon-copy />
              </div>
            </div>
          </actions-button>

          <!-- <div class="modal-voucher__code-block">
            <span
              class="modal-voucher__code"
              :class="{
                '-has-border': hasChild
              }"
            >{{ code }}</span>

            <actions-button
              v-if="hasChild"
              @click="onCopy"
              :appearance="$pepper.Appearance.PRIMARY"
              icon-post="copy"
              :size="$pepper.Size.S"
            />
          </div> -->
          <span
            v-if="from && to"
            class="modal-voucher__interval"
          >{{ $t('conn3ct-wallet.perk_restriction_message', { from: from.toLocaleString(), to: to.toLocaleString() }) }}</span>

          <actions-button
            v-if="voucher.isTransferable && !voucher.isTransferred && type === 'web3'"
            @click="onTransferVoucher"
            :appearance="$pepper.Appearance.PRIMARY"
            class="modal-voucher__transfer"
            :loading="loading"
          >{{ $t('conn3ct-wallet.transfer_voucher') }}</actions-button>

          <actions-button
            v-if="hasChild && type === 'web3'"
            @click="onRevokeTransfer"
            :appearance="$pepper.Appearance.PRIMARY"
            class="modal-voucher__transfer"
            :loading="loading"
          >{{ $t('conn3ct-wallet.revoke_transfer_voucher') }}</actions-button>
        </div>
      </div>
    </popins-modal>
  </div>
</template>

<script>
export default {
  name: 'UiVoucher',

  props: {
    value: {
      type: Object,
      required: true
    },

    type: {
      type: String,
      default: 'web3'
    }
  },

  data() {
    return {
      showModal: false,
      loading: false,
      voucher: null
    }
  },

  computed: {
    hasChild() {
      return !this.$basil.isNil(this.voucher?.childId)
    },

    isTransferable() {
      return this.voucher.isTransferable
    },

    amount() {
      return this.voucher.amount
    },

    unit() {
      if(this.amountType === 'number') return '€'
      return '%'
    },

    code() {
      if(this.hasChild) return this.voucher.child.code

      return this.voucher.code
    },

    name() {
      return this.$basil.get(this.voucher, 'name')
    },

    from() {
      if(this.hasChild) return this.voucher.child.from

      return this.voucher.from
    },

    to() {
      if(this.hasChild) return this.voucher.child.to

      return this.voucher.to
    },

    perks() {
      return this.$basil.get(this.voucher, 'perks', []) || []
    },

    voucherType() {
      return this.voucher.type
    },

    used() {
      if(this.hasChild) return this.voucher.child.used

      return this.voucher.used
    },

    qr() {
      if(this.hasChild) return this.voucher.child.qr

      return this.voucher.qr
    },
  },

  methods: {
    onCopy() {
      if(navigator.clipboard) {
        navigator.clipboard.writeText(this.code)
          .then((r) => {
            this.$notification({
              title: this.$t('conn3ct-wallet.voucher_code_copy_to_clipboard_title'),
              type: this.$pepper.Intent.SUCCESS
            })
          })
          .catch((e) => $console.error('Async: Could not copy text: ', e));
      }
    },

    hasWalletMessage(p) {
      if(this.$basil.isNil(p.walletMessage) || this.translate(p.walletMessage) === '' || this.$basil.isNil(this.translate(p.walletMessage))) return false

      return true
    },

    openModal() {
      if(!this.used) return this.showModal = true
    },

    async onTransferVoucher() {
      try {
        this.loading = true

        let res = await this.$wallet.transferVoucher({ id: this.voucher.id })
        this.voucher = res

        this.$notification({
          title: this.$t('conn3ct-wallet.voucher_transfer_done'),
          type: 'success'
        })
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    },

    async onRevokeTransfer() {
      try {
        this.loading = true

        let res = await this.$wallet.revokeVoucherTransfer({ id: this.voucher.id })
        this.voucher = res

        this.$notification({
          title: this.$t('conn3ct-wallet.voucher_transfer_revoked'),
          type: 'success'
        })
      } catch(e) {
        $console.error(e)
      } finally {
        this.loading = false
      }
    }
  },

  created() {
    this.voucher = this.value
  }
}
</script>
