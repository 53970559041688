<script>
import UiVoucher from '@/components/ui/voucher.vue'

export default {
  name: 'ViewVouchers',

  components: {
    UiVoucher
  },

  data() {
    return {
      loading: false,
      blockedMessage: null,
      selectedTab: 'web3',
      selectedNft: null
    }
  },

  computed: {
    filteredList() {
      return this.list
    },

    list() {
      if(this.selectedTab === 'web3') return this.$vouchers.web3Vouchers?.list ?? []

      return this.$vouchers.web2Vouchers?.list ?? []
    },
  },

  methods: {
    async init() {
      this.loading = true
      this.blockedMessage = null
      this.selectedNft = null

      try {
        await Promise.all([
          this.$vouchers.getWeb3Vouchers(),
          this.$vouchers.getWeb2Vouchers()
        ])
      } catch(e) {
        $console.error(e)

        if(this.$basil.get(e, 'response.status') === 423) {
          this.blockedMessage = this.$basil.get(e, 'response.data.message')
        }
      } finally {
        this.loading = false
      }
    },

    openProfile() {
      this.$router.push({ name: 'sayl-connect_user-profile' }).catch(() => {})
    },

    selectNft(voucher) {
      if(this.selectedNft === voucher.nft.id) {
        this.selectedNft = null
        return
      }

      this.selectedNft = voucher.nft.id
    }
  },

  mounted() {
    this.init()
    const { tab, id } = this.$route.query
    this.selectedTab = tab ?? 'web3'
    this.selectedNft = id ?? null
  }
}
</script>

<template>
  <layout-page
    :back="{ name: 'sayl-connect_dashboard-dashboard' }"
    class="module-vouchers vouchers"
    :subtitle="$t('conn3ct-wallet.vouchers_list_title_description')"
    :title="$t('conn3ct-wallet.vouchers_list_title')"
    :loading="loading"
  >
    <ui-panel
      v-if="blockedMessage"
      class="vouchers__feature-locked"
    >
      <template v-slot:header>
        <h4 class="title">{{ $t(`conn3ct-wallet.${ blockedMessage }_title`) }}</h4>
      </template>

      <div class="body">
        <p>{{ $t(`conn3ct-wallet.${ blockedMessage }_description`) }}</p>

        <actions-button
          @click="openProfile"
          :appearance="$pepper.Appearance.PRIMARY"
          class="vouchers__feature-locked-cta"
        >{{ $t('conn3ct-wallet.open_profile') }}</actions-button>
      </div>
    </ui-panel>

    <template v-else>
      <div class="vouchers__tabs">
        <h4
          @click="selectedTab = 'web3'"
          class="vouchers__tab"
          :class="{ '-is-selected': selectedTab === 'web3' }"
        >{{ $t('conn3ct-wallet.vouchers_tab_web3') }}</h4>

        <h4
          @click="selectedTab = 'web2'"
          class="vouchers__tab"
          :class="{ '-is-selected': selectedTab === 'web2' }"
        >{{ $t('conn3ct-wallet.vouchers_tab_web2') }}</h4>
      </div>

      <ui-panel
        v-if="!filteredList.length"
        class="vouchers__empty"
      >
        <template v-slot:header>
          <h3 class="title">{{ $t('conn3ct-wallet.wallet_no_vouchers_title') }}</h3>
        </template>

        <div class="body">
          <p>{{ $t('conn3ct-wallet.wallet_no_vouchers_description') }}</p>
        </div>
      </ui-panel>

      <div
        v-else-if="selectedTab === 'web3'"
        class="vouchers__web3-list"
      >
        <ui-panel
          v-for="voucher in filteredList"
          class="vouchers__web3-item"
          :class="{
            '-is-active': selectedNft === voucher.nft.id
          }"
          :key="voucher.nft.id"
          :ref="voucher.nft.id"
        >
          <template v-slot:header>
            <div
              @click="() => selectNft(voucher)"
              class="title vouchers__web3-header"
            >
              <div class="vouchers__web3-title">
                <ui-illustration
                  class="vouchers__web3-image"
                  fit="contain"
                  :src="voucher.nft.imageUrl"
                  :type="voucher.nft.imageType"
                />

                <span>{{ voucher.nft.name }}</span>
              </div>

              <div class="vouchers__web3-button">
                <icon-carret :orientation="selectedNft === voucher.nft.id ? 'bottom' : 'top'" />
              </div>
            </div>
          </template>

          <div class="body vouchers__list web3-vouchers">
            <ui-voucher
              v-for="v in voucher.vouchers"
              :key="v.id"
              :value="v"
            />
          </div>
        </ui-panel>
      </div>

      <ui-panel
        v-else
        class="vouchers__web2-list"
      >
        <div class="body vouchers__list">
          <ui-voucher
            v-for="v in filteredList"
            :key="v.id"
            type="web2"
            :value="v"
          />
        </div>
      </ui-panel>
    </template>
  </layout-page>
</template>
